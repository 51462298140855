import React from "react"
import { Link } from "gatsby"

export default function Page2() {
    return (
        <>
            <div>Page2</div>
            <Link to="/">ホームに戻る</Link>
        </>
    )
}